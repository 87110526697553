@import 'var';
@import 'mixins';

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;700&display=swap');

@font-face{
  font-family:"EDF2020W01-Light";
  src:  url("/assets/fonts/EDF2020/EDF2020W01-Light.woff2") format("woff2"),
        url("/assets/fonts/EDF2020/EDF2020W01-Light.woff") format("woff");
        font-display: swap;
}

@font-face{
  font-family:"EDF2020W01-Regular";
  src:  url("/assets/fonts/EDF2020/EDF2020W01-Regular.woff2") format("woff2"),
        url("/assets/fonts/EDF2020/EDF2020W01-Regular.woff") format("woff");
        font-display: swap;
}

@font-face{
  font-family:"EDF2020W01-Bold";
  src:  url("/assets/fonts/EDF2020/EDF2020W01-Bold.woff2") format("woff2"),
        url("/assets/fonts/EDF2020/EDF2020W01-Bold.woff") format("woff");
        font-display: swap;
}

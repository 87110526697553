@import "var";
@import "mixins";
@import "fonts";

* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --app-height: 100%;
}

html {
  transition: filter 0.25s ease-in-out;

  // body {
  // questa regola blocca le animazioni
  //   overflow-x: hidden;
  // }

  // spacca lo scroll nelle pagine con gsap
  // scroll-behavior: smooth;

  &.darkMode {
    filter: saturate(0) invert(1) hue-rotate(180deg);

    img {
      filter: invert(1);
    }
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $baseFont;
  font-size: 16px;
  background-color: $white;
  color: $grey100;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-weight: $fontWeightLight;

  &.page-search {
    main {
      > div {
        .MuiContainer-root:first-child {
          position: relative;
        }
      }
    }
  }

  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    > main {
      flex: 1;
      // overflow-x: hidden;
    }
  }

  a {
    text-decoration: none;
    color: $green700;
  }

  header,
  main,
  footer,
  > div {
    @import "grid";
    @import "typography";

    @import "components/index";
  }

  ::selection {
    color: #fff;
    background: #4f9e30;
  }

  .emptyLayout {
    background-color: $white;
    background-image: url("/assets/images/ed-background.svg");
    background-position: 80%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding: toRem(24) 0;

    @include media-breakpoint-up(lg) {
      background-position: center;
    }

    @include media-breakpoint-down(lg) {
      padding-bottom: toRem(88);
    }

    .heading {
      text-align: center;
      text-transform: uppercase;
      color: $grey100;
    }
    .wayout {
      text-align: center;
      color: $green900;
      cursor: pointer;
    }

    .emptyLayoutFooter {
      z-index: 9999;

      @include media-breakpoint-down(lg) {
        position: fixed;
        width: 100%;
        height: toRem(88);
        left: 0;
        right: 0;
        bottom: 0;
        padding: toRem(16) 0;
        backdrop-filter: blur(2px);
      }

      @include media-breakpoint-up(lg) {
        bottom: toRem(20);
        background-color: transparent;
      }
    }
  }

  .long-form {
    [class^="StickyBar"] {
      position: relative;

      @include media-breakpoint-up(lg) {
        padding: toRem(24) toRem(32);
      }

      @include media-breakpoint-down(lg) {
        position: fixed;
        bottom: toRem(16)
      }

      .MuiTypography-bodyL {
        color: $mirage950;
        font-family: "EDF2020W01-Regular";
        font-size: toRem(16);
        font-weight: 400;
        line-height: 140%;
        text-transform: uppercase;
        letter-spacing: normal;
      }

      .MuiButtonBase-root {
        background: $emerald500;
        font-family: "EDF2020W01-Regular";
        font-size: toRem(14);

        @include media-breakpoint-up(lg) {
          line-height: toRem(27);
          height: toRem(27);
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: toRem(4);
}

/* Track */
::-webkit-scrollbar-track {
  background: $grey10;
  border-radius: toRem(4);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey60;
  border-radius: toRem(4);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $grey80;
}

// modulo mappa text content
[class^="Map_boxContainer"] [class^="Map_mapTextContainer"] {
  > div {
    > p {
      @include font-gen($baseFont, 18, $black, 27, $fontWeightLight, normal);
    }

    > h6 {
      @include font-gen($headingFont, 22, $black, 33, $fontWeightBold, normal);
    }
  }
}

// effetto ripple visibile
header .MuiTouchRipple-root {
  overflow: visible;
  // transform: scale(.5);

  .MuiTouchRipple-child {
    max-width: toRem(160);
    max-height: toRem(160);
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    // transform: scale(.5);
  }

  .MuiTouchRipple-rippleVisible {
  }
}

#CookiebotWidget .CookiebotWidget-main-logo,
#CybotCookiebotDialog #CybotCookiebotDialogPoweredbyCybot {
  display: none !important;
}

// Fix ItalyMap tooltip
.system-tooltip .MuiTooltip-tooltip {
  padding: 4px 8px;
  margin-bottom: 12px !important;
}

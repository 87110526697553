.MuiButton-root {
  transition: all .2s linear;
  min-width: toRem(158);
  height: toRem(40);
  border-radius: toRem(40);
  font-size: toRem(16);
  box-shadow: none;
  text-align: center;
  line-height: 1.2;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    min-width: toRem($buttonWidth);

    padding-left: toRem(24);
    padding-right: toRem(24);

    @media (orientation: portrait) {
      height: toRem($buttonHeight);
      border-radius: toRem($buttonHeight);
      font-size: toRem($buttonFontSize);
    }
  }

  @include media-breakpoint-up(lg) {
    height: toRem($buttonHeight);
    border-radius: toRem($buttonHeight);
    font-size: toRem($buttonFontSize);
  }

  &.MuiButton-sizeSmall {
    @include media-breakpoint-up(md) {
      min-width: toRem(158);

      @media (orientation: portrait) {
        height: toRem(40);
        border-radius: toRem(40);
        font-size: toRem(16);
      }
    }

    @include media-breakpoint-up(lg) {
      height: toRem(40);
      border-radius: toRem(40);
      font-size: toRem(16);
    }
  }

  &.MuiButton-sizeExtraLarge {
    min-width: toRem(158);
    height: toRem(56);
    border-radius: toRem(40);
    font-size: toRem(16);

    @include media-breakpoint-up(lg) {
      height: toRem(88);
      border-radius: toRem(60);
      font-size: toRem(22);
    }
  }

  .MuiTouchRipple-child {
    background-color: $white;
  }

  .buttonText {
    font-family: $baseFont;
    font-weight: 400;
    background-size: 200%;
    background-position: left;
    letter-spacing: 0.02em;
    transition: all .2s linear;
  }

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    >*:nth-of-type(1) {
      font-size: 1.5rem;
    }
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0px 8px 18px -6px rgba(0, 26, 112, 0.1), 0px 12px 42px -4px rgba(0, 26, 112, 0.1);

    .buttonText {
      font-family: $baseFont;
      font-weight: 400;
      background-position: right;
    }
  }

  // Primary
  &.MuiButton-contained {

    &Primary {
      background-color: $green600;
      background-image: $gradientGreenFull;
      background-size: 200%;
      background-position: left;
      color: $white;

      &:hover {
        background-position: right;
      }

      &.Mui-disabled {
        color: $grey40;
        background-image: none;
        background-color: $grey5;
      }

      // .darkMode & {
      //   background-color: $grey20;
      //   background-image: $gradientLightGrey;
      //   &:hover {
      //     background-image: none;
      //     background-color: $grey5;
      //   }
      // }
    }

    &Secondary {
      background-color: $white;
      background-image: $gradientLightGrey;
      color: $green600;
      background-size: 200%;
      background-position: left;

      .buttonText {
        font-family: $baseFont;
        font-weight: 400;
        background-image: $gradientGreenFull;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:hover {
        background-position: right;
      }

      &.Mui-disabled {
        color: $alphaWhite20;
        background-image: none;
        background-color: $alphaWhite10;
      }

      .MuiTouchRipple-child {
        background-color: $grey5;
      }
    }

  }

  // Secondary
  &.MuiButton-outlined {

    &Primary {
      background: linear-gradient(#fff 0 0) padding-box, $gradientGreen border-box;
      border: 1px solid transparent;

      .buttonText {
        font-family: $baseFont;
        font-weight: 400;
        background-image: $gradientGreenFull;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:hover {
        border-color: $green600;
      }

      &:active,
      &:focus {
        border-color: transparent;
        background: linear-gradient(#fff 0 0) padding-box, linear-gradient(90deg, $green600 0%, $green900 74.66%) border-box;
      }

      &.Mui-disabled {
        border-color: $grey40;
        background-color: transparent;
        color: $grey40;
      }

    }

    &Secondary {
      border: 1px solid $white;
      color: $white;
      background-color: transparent;

      &:hover {
        background: $alphaWhite10;
      }

      &.Mui-disabled {
        color: $alphaWhite20;
        border-color: $alphaWhite20;
        background-color: transparent;
      }
    }

  }

  // Tertiary
  &.MuiButton-text {

    &Primary {

      .buttonText {
        font-family: $baseFont;
        font-weight: 400;
        background-image: $gradientGreenFull;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:hover {
        background-color: transparent;
      }

      &.Mui-disabled {
        color: $grey40;

        .buttonText {
          background: none;
          -webkit-background-clip: initial;
          -webkit-text-fill-color: initial;
        }
      }
    }

    &Secondary {
      color: $white;

      &:hover,
      &:focus,
      &:active {
        background-color: $alphaWhite10;
      }

      &.Mui-disabled {
        color: $alphaWhite20;
      }
    }

  }

  // Link
  &.MuiButton-link {
    height: auto;
    width: auto;
    min-width: auto;
    padding: 0;
    border-radius: 0;

    &Primary {
      &:hover {
        box-shadow: none;
        background: none;
      }

      .buttonText {
        font-family: $baseFont;
        font-weight: 400;
        background-image: $gradientGreenFull;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &Secondary {
      .buttonText {
        font-family: $baseFont;
        font-weight: 400;
      }
    }
  }

  // Link
  &.MuiButton-linkwhite {
    height: auto;
    width: auto;
    min-width: auto;
    padding: 0;
    border-radius: 0;

    &Primary {
      &:hover {
        box-shadow: none;
        background: none;
      }

      .buttonText {
        font-family: $baseFont;
        font-weight: 400;
        color: $white;
      }
    }

    &Secondary {
      .buttonText {
        font-family: $baseFont;
        font-weight: 400;
      }
    }
  }

  // Transparent
  &.MuiButton-transparent {

    &Primary {
      background: transparent;
      border: 1px solid $white;

      .buttonText {
        font-family: $baseFont;
        font-weight: 400;
        background: $white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:hover {
        border-color: $white;
        background: $alphaWhite10;
      }

      &:active,
      &:focus {
        border-color: $white;
        background: transparent;
      }

      &.Mui-disabled {
        border-color: $grey40;
        background-color: transparent;
        color: $grey40;
      }

    }

    &Secondary {
      border: 1px solid $white;
      color: $white;
      background-color: transparent;

      &:hover {
        background: $alphaWhite10;
      }

      &.Mui-disabled {
        color: $alphaWhite20;
        border-color: $alphaWhite20;
        background-color: transparent;
      }
    }

  }

}

.MuiFab-root {
  // Default is Large
  width: toRem(48);
  height: toRem(48);
  box-shadow: none;

  &.MuiFab-sizeMedium {
    width: toRem(44);
    height: toRem(44);
  }

  &.MuiFab-sizeSmall {
    width: toRem(40);
    height: toRem(40);
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 8px 18px -6px rgba(0, 26, 112, 0.1), 0px 12px 42px -4px rgba(0, 26, 112, 0.1);
  }

  &.MuiFab-extended {
    width: auto;
  }

  .MuiSvgIcon-root {
    font-size: toRem(28);
  }

  &.MuiFab-primary {
    background: linear-gradient(90deg, #0E6814 0%, #63B23A 74.66%);

    &:hover {
      background-color: $green600;
    }

    &.Mui-disabled {
      background-color: $grey5;
      color: $grey40;
    }
  }

  &.MuiFab-secondary {
    background-color: transparent;
    border: 1px solid $green600;
    color: $green600;

    &:hover {
      border-color: $green400;
      color: $green400;
    }

    &.Mui-disabled {
      border-color: $grey40;
      color: $grey40;
    }
  }

}

.cta-download,
.cta-callmenow {
  padding: toRem(24);
  display: inline-flex;
  align-items: center;
  gap: toRem(24);
  background-color: $grey10;
  color: $grey100 !important;
  text-decoration: none !important;
  transition: all .2s;

  &:hover { background-color: $grey20; }

  &::after,
  &::before {
    font-family: 'Material Icons';
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: toRem(24);
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    color: $grey100;
  }
}

.cta-download {
  &::before {
    content: "\e415";
  }
  &::after {
    color: $green700;
    font-size: toRem(35);
    content: "\f090";
  }
}

.cta-callmenow {
  span:nth-child(2) {
    font-size: toRem(24);
    color: $green700 !important;
    line-height: 2.25rem;
    font-weight: 400;
  }
  &::after {
    color: $green700;
    font-size: toRem(35);
    content: "\e0b0";
  }
}
.MuiAccordion-root {
  &:first-of-type, &:last-of-type {border-radius: toRem(8)}
  &:before {content: none;}

  // padding: toRem(24) toRem(27);

  margin-bottom: toRem(24);
  border-radius: toRem(8);
  box-shadow: 0px 4px 6px -4px rgba(0, 26, 112, 0.05), 0px 8px 8px -4px rgba(0, 26, 112, 0.05);
  transition: all 0.25s linear;

  @include media-breakpoint-up(lg) {

    margin-bottom: toRem(11);
  }

  &.Mui-expanded {
    box-shadow: 0 8px 28px -6px rgba(0, 26, 112, 0.1), 0 18px 88px -4px rgba(0, 26, 112, 0.1);
    margin: 0 0 toRem(24);
    @include media-breakpoint-up(lg) {
      margin: 0 0 toRem(11);
    }
    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  .MuiAccordionSummary-root {
    padding: toRem(32) toRem(22);
    min-height: auto;

    @include media-breakpoint-up(lg) {
      padding: toRem(40);
    }

    .MuiAccordionSummary-content	{
      margin: 0;
    }
  }

  .MuiAccordionDetails-root {
    margin-top: toRem(8);
    padding: 0 toRem(22) toRem(32);

    @include media-breakpoint-up(lg) {
      padding: 0 toRem(40) toRem(32);
    }
  }
}

@import "var";

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6 {
  font-family: $headingFont;
  line-height: $headingLineHeight;
  transition: all 0.3s linear;
  color: $blue900;
  font-weight: $fontWeightRegular;

  span {
    color: inherit;
  }
}

h1,
.h1,
.MuiTypography-h1 {
  font-size: toRem(40);
  line-height: toRem(48);

  @include media-breakpoint-up(md) {
    @media (orientation: portrait) {
      font-size: toRem(56);
      line-height: toRem(68);
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: toRem(72);
    line-height: toRem(80);
  }
}

h2,
.h2,
.MuiTypography-h2 {
  font-size: toRem(36);
  line-height: toRem(44);

  @include media-breakpoint-up(md) {
    @media (orientation: portrait) {
      font-size: toRem(48);
      line-height: toRem(62);
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: toRem(56);
    line-height: toRem(72);
  }
}

h3,
.h3,
.MuiTypography-h3 {
  font-size: toRem(32);
  line-height: toRem(40);

  @include media-breakpoint-up(md) {
    @media (orientation: portrait) {
      font-size: toRem(40);
      line-height: toRem(52);
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: toRem(44);
    line-height: toRem(56);
  }
}

h4,
.h4,
.MuiTypography-h4 {
  font-size: toRem(28);
  line-height: toRem(36);

  @include media-breakpoint-up(md) {
    @media (orientation: portrait) {
      font-size: toRem(32);
      line-height: toRem(42);
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: toRem(36);
    line-height: toRem(48);
  }
}

h5,
.h5,
.MuiTypography-h5 {
  font-size: toRem(22);
  line-height: toRem(33);

  @include media-breakpoint-up(md) {
    @media (orientation: portrait) {
      font-size: toRem(24);
      line-height: toRem(36);
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: toRem(28);
    line-height: toRem(40);
  }
}

h6,
.h6,
.MuiTypography-h6 {
  font-size: toRem(20);
  line-height: toRem(30);

  @include media-breakpoint-up(md) {
    @media (orientation: portrait) {
      font-size: toRem(22);
      line-height: toRem(33);
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: toRem(24);
    line-height: toRem(36);
  }
}

.MuiTypography-subtitle {
  font-family: $baseFont;
  font-size: toRem(18);
  line-height: toRem(27);
  font-weight: $fontWeightRegular;

  @include media-breakpoint-up(md) {
    @media (orientation: portrait) {
      font-size: toRem(20);
      line-height: toRem(30);
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: toRem(22);
    line-height: toRem(33);
  }

  strong {
    font-weight: $fontWeightMedium;
  }

  span {
    color: inherit;
  }
}

.MuiTypography-bodyL {
  font-family: $baseFont;
  font-size: toRem(16);
  line-height: toRem(24);

  @include media-breakpoint-up(lg) {
    font-size: toRem(18);
    line-height: toRem(27);
  }

  strong {
    font-weight: $fontWeightMedium;
  }

  span {
    color: inherit;
  }
}

.MuiTypography-bodyM {
  font-family: $baseFont;
  font-size: toRem(14);
  line-height: toRem(21);

  @include media-breakpoint-up(lg) {
    font-size: toRem(16);
    line-height: toRem(24);
  }

  strong {
    font-weight: $fontWeightMedium;
  }

  span {
    color: inherit;
  }
}

.MuiTypography-bodyS {
  font-family: $baseFont;
  font-size: toRem(12);
  line-height: toRem(18);
  font-weight: $fontWeightRegular;

  @include media-breakpoint-up(lg) {
    font-size: toRem(14);
    line-height: toRem(21);
  }

  strong {
    font-weight: $fontWeightMedium;
  }

  span {
    color: inherit;
  }
}

.MuiTypography-overline {
  font-family: $baseFont;
  font-size: toRem(14);
  line-height: toRem(21);
  // letter-spacing: 0.1em;
  letter-spacing: normal;
  text-transform: uppercase;
  font-weight: $fontWeightRegular;

  @include media-breakpoint-up(lg) {
    font-size: toRem(16);
    line-height: toRem(24);
  }

  span {
    color: inherit;
  }
}

.MuiTypography-description {
  font-family: $baseFont;
  font-size: toRem(10);
  line-height: toRem(15);
  font-weight: $fontWeightRegular;

  @include media-breakpoint-up(lg) {
    font-size: toRem(12);
    line-height: toRem(18);
  }

  span {
    color: inherit;
  }
}

.MuiTypography-caption {
  font-family: $baseFont;
  font-size: toRem(8);
  line-height: toRem(12);
  font-weight: $fontWeightRegular;

  @include media-breakpoint-up(lg) {
    font-size: toRem(10);
    line-height: toRem(15);
  }

  span {
    color: inherit;
  }
}

.MuiChip-root {
  @include font-gen($baseFont, 14, $green700, 21, $fontWeightMedium, normal);
  padding: 0 toRem(2);
  border-radius: toRem(4);

  &.MuiChip-colorPrimary {
    &.MuiChip-filled {
      background-color: $green700;
      color: $white;
    }

    &.MuiChip-outlined {
      color: $green700;
      border-color: $green700;
    }
  }

  @include media-breakpoint-up(lg) {
    @include font-gen($baseFont, 16, $green700, 24, $fontWeightMedium, normal);
    padding: toRem(4) toRem(2);
  }

  @include media-breakpoint-down(lg) {
    &:active,
    &:hover {
      background-color: $grey5;
      box-shadow: none;
    }
  }
}

.long-form {
  .MuiTypography-h1 {
    font-family: "mixta-sharp";
    font-size: toRem(112);
    color: $athensGray50;
    font-style: normal;
    font-weight: 800;
    line-height: 80%;
    letter-spacing: -2.24px;

    @include media-breakpoint-down(lg) {
      font-size: toRem(66);
      letter-spacing: -1.32px;
    }
  }

  .MuiTypography-h2 {
    font-family: "mixta-sharp";
    font-size: toRem(56);
    color: $mirage50;
    font-style: normal;
    font-weight: 800;
    line-height: 80%;
    letter-spacing: -1.12px;

    @include media-breakpoint-down(lg) {
      font-size: toRem(40);
      letter-spacing: toRem(-0.8);
    }
  }

  .MuiTypography-h3 {
    font-family: "mixta-sharp";
    font-size: toRem(48);
    color: $mirage50;
    font-style: normal;
    font-weight: 800;
    line-height: 80%;
    letter-spacing: -1.12px;

    @include media-breakpoint-down(lg) {
      font-size: toRem(32);
      line-height: 110%;
      letter-spacing: -0.72px;
    }
  }

  .MuiTypography-h4 {
    font-family: "mixta-sharp";
    font-size: toRem(40);
    color: $mirage950;
    font-style: normal;
    font-weight: 800;
    line-height: 96%;
    letter-spacing: -0.8px;
    @include media-breakpoint-down(lg) {
      font-size: toRem(32);
    }
  }

  .MuiTypography-h5 {
    font-family: "mixta-sharp";
    font-size: toRem(32);
    color: $mirage950;
    font-style: normal;
    font-weight: 800;
    line-height: 96%;
    letter-spacing: -0.8px;
    @include media-breakpoint-down(lg) {
      font-size: toRem(24);
    }
  }

  .MuiTypography-bodyM {
    font-family: "EDF2020W01-Regular";
    font-size: toRem(18);
    color: $mirage50;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    @include media-breakpoint-down(lg) {
      font-size: toRem(16);
    }
    
    strong {
      font-weight: $fontWeightBold;
    }
  }

  .MuiTypography-bodyS {
    font-family: "EDF2020W01-Regular";
    font-size: toRem(14);
    color: $athensGray50;
    font-style: normal;
    font-weight: 400;
    line-height: 80%;
  }

  .MuiTypography-caption {
    font-family: "EDF2020W01-Regular";
    color: $mirage950;
    font-style: normal;
    font-weight: 400;
    font-size: toRem(16);
    line-height: 140%;

    @include media-breakpoint-down(lg) {
      font-size: toRem(14);
    }
  }

  .MuiTypography-overline {
    font-family: "EDF2020W01-Regular";
    font-size: toRem(14);
    color: $mirage950;
    font-style: normal;
    font-weight: 700;
    line-height: 80%;
    text-decoration: underline;
    text-transform: uppercase;
  }

  .MuiTypography-bodyL {
    font-family: "mixta-sharp";
    font-size: toRem(80);
    color: $mirage300;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -1.6px;
  }

  .MuiTypography-description {
    font-family: "EDF2020W01-Regular";
    font-size: toRem(22);
    color: $mirage950;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    @include media-breakpoint-down(lg) {
      font-size: toRem(16);
    }

    strong {
      font-family: "EDF2020W01-Bold";
    }
  }
}

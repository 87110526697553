.MuiPagination-root	{
  &.MuiPagination-text {
    
    .MuiPagination-ul {
      .MuiPaginationItem-root {

        &.MuiPaginationItem-page {
          border: none;
          color: $green700;
          background-color: transparent;
          &.Mui-selected {
            color: $green900;
            font-weight: 500;
          }
        }

        &.MuiPaginationItem-previousNext {
          border: 1px solid $green700;
          color: $green700;

          &.Mui-disabled {
            border-color: $grey40;
            color: $grey40;
          }
        }

      }
    }
  }
}

// Stepper
.MuiStepper-root {
  .MuiStep-root {
    .MuiStepIcon-root {
      border: 1px solid $blue900;
      border-radius: 50%;
      color: transparent;

      .MuiStepIcon-text {
        fill: $blue900;
      }

      &.Mui-active {
        color: $blue900;
        .MuiStepIcon-text {
          fill: $white;
        }
      }

      &.Mui-completed {
        color: $green700;
        border-color: $green700;
        cursor: pointer;
      }
    }
  }

  &.MuiStepper-horizontal {
    
  }

  // Desktop
  &.MuiStepper-vertical {
    .MuiStep-root {
      svg {
        @include media-breakpoint-up(lg) {
          width: toRem(40);
          height: toRem(40);
        }
      }
    }
    .MuiStepConnector-root {
      margin-left: toRem(20);
      .MuiStepConnector-line {
        min-height: toRem(80);
        border-left: 1px dashed $grey50;
      }
    }
  }
}
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $grey30;

  caption {
    padding: toRem(12);
    border: 1px solid $grey30;
    border-bottom: none;
    background-color: $blue50;
    font-weight: bold;
  }

  th, td {
    border: 1px solid $grey30;
    padding: toRem(16) toRem(24);
    text-align: left;
  }

  th {
    background-color: $grey5;
  }
}
//todo: integrare qui le select del form di contatto
.MuiFormControl-root {
  .MuiInputLabel-root {
    text-transform: uppercase;
    color: $grey100;
  }

  // Input
  .MuiInputBase-root {

    &:after {
      border-bottom-color: $supporto500;
    }

    &.Mui-error {
      &:after {
        border-bottom-color: $error50;
      }
    }

    // Label
    .MuiInputLabel-root {
      color: $grey100;

      // Focused
      &.Mui-focused	{
        color: $supporto500;
        &.Mui-error {
          color: $error50;
        }
      }

      // Filled
      &.MuiFormLabel-filled {
        + .MuiInput-root {
          &::after {
            transform: scaleX(1) translateX(0);
            border-bottom-color: $grey100;
          }
        }
      }

      // Error
      &.Mui-error {
        color: $grey100;
        &.MuiFormLabel-filled {
          color: $error50;
        }
      }

    }

    .MuiInput-input {
      color: $blue900;
      &::placeholder {
        color: $grey100;
      }
      &:-webkit-autofill {
        transition: background-color 9999s ease-in-out 0s;
      }
    }

    // Helper Text
    .MuiFormHelperText-root {
      color: $grey80;
      &.Mui-error {
        color: $error50;
      }
    }

    &.MuiInputBase-colorSecondary {
      color: $white;
      &:before { border-bottom-color: rgba($white, 0.4); }
      &:after { border-bottom-color: $white; }

      .MuiInputLabel-root {
        color: $white;
        &.Mui-focused	{
          color: $white;
        }
        
      }

      .MuiInput-input {
        color: $white;
        &::placeholder {
          color: $white;
        }
      }
    }

    &.MuiInputBase-colorSuccess {
      &:after {
        border-bottom-color: $success;
      }
    }

    .MuiInputBase-input[type="file"]::-webkit-file-upload-button {
      display: none;
    }

  }

  // Select
  .MuiSelect-select	{
    .contact-form & { //todo: class wrapper for form contact

    }
    &:focus {
      background-color: transparent;
    }
  }
  .MuiSelect-icon {
    color: $green700;
  }

  .MuiFormControlLabel-root {
    .MuiFormLabel-root {
      pointer-events: none;
    }
  }
}

.MuiAutocomplete-root {
  
  .MuiFormControl-root {
    
    .MuiChip-root {
      // border: 1px solid red !important;
      margin-top: 0;
      height: toRem(28);
    }
  }
  
}

// Checkbox and Radio
.MuiCheckbox-root,
.MuiRadio-root {
  color: $green700;
  &:hover {
    color: $green600;
  }

  &.Mui-disabled {
    color: $grey40;
  }
  // Disabled
}

// Switch
.MuiSwitch-root {
  .MuiSwitch-switchBase {
    color: $white;
  }

  .MuiSwitch-track {
    opacity: 1;
    background-color: $grey50;
  }

  &:hover {
    .MuiSwitch-track {
      background-color: $grey60;
    }
  }

  // Disabled
  .Mui-disabled	{
    color: $grey30;
    + .MuiSwitch-track {
      opacity: 1;
      background-color: $grey10;
    }
  }

  // Checked
  .Mui-checked {
    + .MuiSwitch-track {
      opacity: 1;
      background-color: $green700;
    }

    &:hover {
      + .MuiSwitch-track {
        opacity: 1;
        background-color: $green500;
      }
    }

    &.Mui-disabled {
      color: $grey30;
      + .MuiSwitch-track {
        opacity: 1;
        background-color: $grey10;
      }
    }
  }

}

.MuiMenu-root {
  .MuiPaper-root	{
    box-shadow: none;
    border: 1px solid $grey40;
    border-radius: 0;
    .MuiList-root{
      .MuiMenuItem-root	{
        font-size: toRem(18);
        color: $grey80;

        &.Mui-selected {
          color: $grey100;
          background-color: transparent;
          font-weight: bold;
        }

        &:hover {
          color: $grey100;
          background-color: $blue50;
        }
      }
    }
  }
}

.contactUs-form {
  &.contactUs-page {
    min-height: calc(100vh - toRem(100));
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .title {
    color: $grey100;
    max-width: toRem(800);
    text-align: center;
    margin: 0 auto toRem(50);
  }
  .subtitle {
    color: $grey100;
    text-align: center;
    margin: 0 auto;
    padding: toRem(30) 0;
    max-width: toRem(520);
  }
}

.contactForm-Paper {
  .MuiList-root,
  .MuiAutocomplete-listbox {
    .MuiMenuItem-root {
      padding-left: toRem(8);
      .MuiCheckbox-root {
        padding: 0 toRem(10) 0 0;
      }
    }
  }
}